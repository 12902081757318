<script setup>
const props = defineProps(['display'])
const {locale} = useI18n()
const switchLocalePath = useSwitchLocalePath()

const runtimeConfig = useRuntimeConfig()
const {$isInMobileAppWebView} = useNuxtApp()
const {postMessageChangeAppLanguage} = usePostMessage()

const locales = runtimeConfig.public.localeOptions

const langLabel = (code) => {
  return locales.find((locale) => locale.code === code) || {code: code, label: code, shortLabel: code}
}

const hideLangDropDown = computed(() => typeof window !== 'undefined' && $isInMobileAppWebView.value)

const handleLanguageSwitcher = () => {
  if ($isInMobileAppWebView.value) {
    postMessageChangeAppLanguage()
    return
  }
}
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-header-languages.css';
</style>

<template>
  <div class="nu-header-languages">
    <a
      id="header-languages-dropdown"
      aria-expanded="false"
      class="dropdown-handler"
      data-bs-toggle="dropdown"
      role="button"
      @click="handleLanguageSwitcher"
    >
      <template v-if="props.display == 'NuSvgIcon'">
        <img
          alt="globe"
          class="globe-icon"
          src="/images/common/globe-icon.svg"
          width="18"
        />
        <img
          alt="globe"
          class="globe-icon-active"
          src="/images/common/globe-icon-active.svg"
          width="18"
        />
      </template>
      <template v-else>
        {{ langLabel(locale).shortLabel }}
      </template>
    </a>
    <ul
      aria-labelledby="header-languages-dropdown"
      class="dropdown-menu"
      v-if="!hideLangDropDown"
    >
      <li
        :key="index"
        v-for="(localeKey, index) in locales"
      >
        <NuxtLink
          :class="{'router-link-active': selectedLanguage === localeKey.code}"
          :to="switchLocalePath(localeKey.code)"
          class="dropdown-item"
          data-testid="language-switch-dropdown"
        >
          {{ langLabel(localeKey.code).label }}
          <NuSvgIcon
            class="profile-right-panel-tick"
            name="checkmark"
          />
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>
